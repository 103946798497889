import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { SanitySellPageStickyCta } from 'graphql-types';
import { StickyCta } from './StickyCta';
import { ThemeContext } from 'styled-components';

interface Props {
  fields: SanitySellPageStickyCta;
}

export const SanityStickyCta = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);

  return (
    <StickyCta
      backgroundColor={fields.backgroundColor?.hex || theme.colors.default}
      desktopEnabled={fields.desktopEnabled ?? false}
      fullWidthMobileCta={fields.fullWidthMobileCta ?? true}
      copy={fields.sctaCopy}
      textColor={fields.textColor?.hex}
      buttonText={fields.buttonText}
      ctaLink={fields.ctaLink || ''} // deprecated
      ctaLinkObject={fields.ctaLinkObject}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageStickyCta on SanitySellPageStickyCta {
    _key
    _type
    __typename
    sctaCopy
    textColor {
      hex
    }
    desktopEnabled
    buttonText
    ctaLink
    ctaLinkObject {
      ...LinkObject
    }
    backgroundColor {
      hex
    }
    fullWidthMobileCta
  }
  fragment SanitySellPageStickyCtaShowHide on SanitySellPageStickyCtaShowHide {
    _type
    __typename
    enableHide
  }
`;
