import styled, { css } from 'styled-components';
import { height, LayoutProps, width } from 'styled-utils';
import { device } from 'theme';

export const Media = {
  onlyMobile: () => css`
    @media (min-width: ${device.medium.width}) {
      display: none !important;
    }
  `,
  onlyDesktop: () => css`
    @media (max-width: 51.938em) {
      display: none !important;
    }
  `,
};

export const OnlyMobile = styled.div<LayoutProps>`
  ${Media.onlyMobile};
  ${height};
  ${width};
`;

export const OnlyDesktop = styled.div<LayoutProps>`
  ${Media.onlyDesktop};
  ${height};
  ${width};
`;
