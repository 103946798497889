import React, { useContext } from 'react';
import { SanitySellPageStickyHeader } from 'graphql-types';
import { ThemeContext } from 'styled-components';
import { StickyHeader } from './StickyHeader';

export const SanityStickyHeader: React.FC<{
  fields: SanitySellPageStickyHeader;
}> = ({ fields }) => {
  const theme = useContext(ThemeContext);
  return (
    <StickyHeader
      bgColor={fields.bgColor || { hex: theme.colors.basic100 }}
      desktopHeight={fields?.desktopHeight || 70}
      mobileHeight={fields?.mobileHeight || 60}
      title={fields?.title}
      url={fields?.url}
      logoImage={fields?.logoImage}
      logoAlignment={fields.logoAlignment || 'left'}
    />
  );
};
