import styled from 'styled-components';
import {
  background,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  BackgroundProps,
} from 'styled-utils';

export const Container = styled.div<BackgroundProps & HeightProps>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  z-index: 99;
  width: 100%;
  ${height};
  ${background}
`;

export const LogoContainer = styled.div<JustifyContentProps>`
  display: flex;
  width: 100%;
  position: relative;
  ${justifyContent};
  padding: 0 15px 0 15px;
`;
