import React from 'react';
import { Content, Copy } from './styles';
import { StickyCtaProps } from './model';
import { Button } from 'components/Common/Button';
import { Wrapper } from 'components/Common/Layout';
import { logEvent, awaitClick } from 'helpers/Amplitude';
import { StickyElement } from 'components/Common/StickyElement/StickyElement';
import { getUrl } from 'helpers/LinkObject';

export const StickyCta: React.FC<StickyCtaProps> = ({
  desktopEnabled,
  fullWidthMobileCta,
  copy,
  textColor,
  buttonText,
  backgroundColor,
  ctaLink, // deprecated
  ctaLinkObject,
  position,
}): React.ReactElement => {
  return (
    <StickyElement
      backgroundColor={backgroundColor}
      position={position}
      desktopEnabled={desktopEnabled}
      className="StickyCta"
    >
      <Wrapper>
        <Content>
          <Copy color={textColor} className="Text">
            {copy}
          </Copy>
          <Button
            className="CTA"
            href={getUrl(ctaLinkObject, ctaLink)}
            onClick={awaitClick((e) => {
              let promise = logEvent('Sell page: Sticky CTA Click', {
                key: e.currentTarget.href,
              });
              if (ctaLink && ctaLink.charAt(0) === '#') {
                // Don't await the logEvent when navigating within the page.
                promise = Promise.resolve();
              }
              return promise;
            })}
            fullWidthMobile={fullWidthMobileCta}
          >
            {buttonText}
          </Button>
        </Content>
      </Wrapper>
    </StickyElement>
  );
};
