import styled from 'styled-components';
import { variant, style } from 'styled-system';
import { device } from 'theme';
import {
  alignItems,
  background,
  borderRadius,
  display,
  fontSize,
  flexDirection,
  lineHeight,
  margin,
  maxWidth,
  width,
  BackgroundProps,
  BorderRadiusProps,
  FlexDirectionProps,
  textAlign,
  TextAlignProps,
} from 'styled-utils';

import { SellPageContainer } from 'components/Common/Layout';
import { Media } from 'components/Common/Media';
import { MediaWithTextProps } from './model';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { standardTagMixin } from 'components/Common/Text';

// Current issue with styled-system using color attribute
// https://github.com/styled-system/styled-system/issues/464
const textColor = style({
  key: 'colors',
  cssProperty: 'color',
  prop: 'textColor',
});

export const StyledMediaWithText = styled.div<
  BackgroundProps & { variant?: string }
>`
  ${background};
  line-height: 1.222;
  ${variant({
    variants: {
      smallLadder: {
        maxWidth: '1330px',
        margin: '0px auto',
      },
    },
  })};
`;

export const StyledMediaWithTextContainer = styled(
  SellPageContainer
)<MediaWithTextProps>`
  ${variant({
    variants: {
      small: {
        paddingTop: [0, 25, 0],
        paddingBottom: [20, 20, 80],
        paddingLeft: [20, 0],
        paddingRight: [20, 0],
      },
      large: {
        paddingTop: [30, 25, 55],
        paddingBottom: [25, 25, 55],
        textAlign: 'center',
        paddingLeft: [20, 0],
        paddingRight: [20, 0],
      },
      split: {
        paddingTop: [25, 0],
        paddingBottom: 0,
        textAlign: 'center',
        paddingLeft: [20, 0],
        paddingRight: [20, 0],
      },
      splitSlider: {
        paddingTop: [0, 0],
        paddingBottom: 0,
        textAlign: 'center',
        paddingLeft: [0],
        paddingRight: [0],
      },
      smallLadder: {
        paddingTop: [0, 25, 0],
        paddingBottom: [20, 20, 0],
        paddingLeft: [35, 35, 0],
        paddingRight: [35, 35, 0],
      },
    },
  })};
`;

export const StyledMediaWithTextWrap = styled.div<FlexDirectionProps>`
  ${flexDirection};
  ${display({ display: [null, null, 'flex'] })};
  ${alignItems({ alignItems: [null, null, 'center'] })};
`;

export const StyledMediaWithTextInfo = styled.div<MediaWithTextProps>`
  min-width: 50%;
  overflow: hidden;
  text-align: left;

  ${(props) => props.mobile && Media.onlyMobile};
  ${(props) => !props.mobile && Media.onlyDesktop};

  ${variant({
    variants: {
      small: {
        paddingLeft: [0, 80],
        paddingRight: [0, 80],
        paddingTop: [0, 0],
      },
      large: {
        paddingLeft: [0, 0, 60],
        paddingRight: [0, 0, 80],
        paddingTop: [20, 20, 0],
      },
      split: {
        paddingLeft: [0, 0, 60],
        paddingRight: [0, 0, 80],
        paddingTop: [20, 20, 0],
        paddingBottom: [20, 0, 0],
      },
      splitSlider: {
        paddingLeft: [20, 0, 60],
        paddingRight: [20, 0, 0],
        paddingTop: [20, 20, 0],
        paddingBottom: [20, 0, 0],
      },
      smallLadder: {
        paddingLeft: [0, 0, 70],
        paddingRight: [0, 0, 70],
        paddingTop: [10, 10, 0],
        paddingBottom: [0, 0, 0],
        flex: 1,
      },
    },
  })};

  a {
    ${margin({ margin: ['0 auto', '0', '0'] })};
  }
`;

export const StyledMediaWithTextMedia = styled.div<MediaWithTextProps>`
  min-width: 50%;
  position: relative;

  ${(props) => props.mobile && Media.onlyMobile};
  ${(props) => !props.mobile && Media.onlyDesktop};
`;

export const StyledMediaWithTextMediaLadderSmall = styled.div<MediaWithTextProps>`
  position: relative;
  height: 185px;
  width: 220px;

  ${(props) => props.mobile && Media.onlyMobile};
  ${(props) => !props.mobile && Media.onlyDesktop};
`;

export const StyledMediaWithTextMediaWrap = styled.div<MediaWithTextProps>`
  ${(props) => props.mobile && Media.onlyMobile};
  @media (max-width: ${device.medium.width}) {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 25px;
    text-align: left;
    align-items: flex-start;
    ${variant({
      variants: {
        embedAllTop: {
          top: '0px',
          alignSelf: 'center',
          alignItems: 'center',
        },
      },
    })};
  }
`;

export const StyledPreTitle = styled.p<MediaWithTextProps & TextAlignProps>`
  ${textColor};
  ${width({ width: ['100%', '100%', null] })};
  ${(props) => (props.mobile ? Media.onlyMobile : Media.onlyDesktop)};
  font-size: ${({ theme }) => theme.fontSizes.xSmall};
  font-weight: 400;
  margin-bottom: 15px;
  ${textAlign};
  ${variant({
    variants: {
      split: {
        paddingTop: [20, 0, 0],
        marginBottom: [0, 15, 15],
      },
      splitSlider: {
        paddingTop: [20, 0, 0],
        marginBottom: [0, 15, 15],
      },
    },
  })};
  ${standardTagMixin};
`;

export const StyledMediaWithTextTitle = styled.h2<
  MediaWithTextProps & TextAlignProps
>`
  ${textColor};
  ${width({ width: ['100%', '100%', null] })};
  ${(props) => props.mobile && Media.onlyMobile};
  ${(props) => !props.mobile && Media.onlyDesktop};
  font-weight: 900;
  margin-bottom: 15px;
  ${textAlign}

  && {
    ${({ theme }) =>
      variant({
        variants: {
          small: {
            fontSize: [26, 32],
            lineHeight: ['28px', '40px'],
          },
          large: {
            fontSize: [26, 36],
            lineHeight: ['28px', '48px'],
          },
          split: {
            fontSize: [26, 36],
            lineHeight: ['28px', '48px'],
            paddingTop: [20, 0, 0],
            marginBottom: [0, 15, 15],
          },
          splitSlider: {
            fontSize: [26, 36],
            lineHeight: ['28px', '48px'],
            paddingTop: [20, 0, 0],
            marginBottom: [0, 15, 15],
            paddingLeft: [15, 0],
            paddingRight: [15, 0],
          },
          smallLadder: {
            marginTop: '1rem',
            ...theme.typography.heading3,
            paddingTop: [20, 0, 0],
            marginBottom: 0,
          },
        },
      })};
  }

  ${standardTagMixin};
`;

export const StyledMediaWithTextText = styled.div<MediaWithTextProps>`
  div p {
    ${textColor};
    ${(props) => props.mobile && Media.onlyMobile};
    ${(props) => !props.mobile && Media.onlyDesktop};
    font-weight: normal;
    ${maxWidth({ maxWidth: ['100%', '100%', '300px'] })};
    ${lineHeight({ lineHeight: ['19px', '25px'] })};
    ${fontSize({ fontSize: ['14px', '18px'] })};
    ${width({ width: ['100%', '100%', null] })};

    ${({ theme }) =>
      variant({
        variants: {
          small: {
            maxWidth: ['100%', '100%', '300px'],
          },
          large: {
            maxWidth: ['100%', '100%', '476px'],
          },
          split: {
            maxWidth: ['100%', '100%', '476px'],
          },
          splitSlider: {
            maxWidth: ['100%', '100%', '476px'],
          },
          smallLadder: {
            maxWidth: ['100%', '100%', '700px'],
            ...theme.typography.default,
          },
        },
      })};
  }

  ul {
    list-style: disc inside;
  }
`;

export const StyledMediaWithTextImg = styled(GatsbyImage)<BorderRadiusProps>`
  ${borderRadius};
  display: block;
  width: 100%;
  overflow: hidden;
`;

export const StyledMediaWithTextImgLadderSmall = styled(
  GatsbyImage
)<BorderRadiusProps>`
  ${borderRadius};
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const StyledMediaWithTextSplitImg = styled(GatsbyImage)`
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-width: 100%;
  ${margin({ marginBottom: [20, 40] })};
`;
