import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { StickyCtaShowHideProps } from './model';

export const StickyCtaShowHide = ({
  enableHide,
}: StickyCtaShowHideProps): React.ReactElement => {
  const [hideCta, setHideCta] = useState(true);
  const anchorEl = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!anchorEl?.current) return;
    const elem: HTMLDivElement = anchorEl?.current;
    setHideCta(elem.getBoundingClientRect().y - window.innerHeight < 0);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <>
      {hideCta && (
        <Helmet>
          <style>{`.stky {display: ${enableHide ? 'none' : 'block'};}`}</style>
        </Helmet>
      )}
      <div ref={anchorEl} />
    </>
  );
};
