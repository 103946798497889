import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import {
  StyledMediaWithText,
  StyledMediaWithTextContainer,
  StyledPreTitle,
  StyledMediaWithTextTitle,
  StyledMediaWithTextText,
  StyledMediaWithTextWrap,
  StyledMediaWithTextInfo,
  StyledMediaWithTextMedia,
  StyledMediaWithTextMediaLadderSmall,
  StyledMediaWithTextMediaWrap,
  StyledMediaWithTextImg,
  StyledMediaWithTextImgLadderSmall,
} from './styles';
import { ScreenAware } from 'components/Common/Placement/Placement';
import {
  Maybe,
  SanityBlock,
  SanityImage,
  SanityLinkObject,
} from 'graphql-types';
import { Button } from 'components/Common/Button';
import {
  logEvent,
  awaitClick,
  mapAndFormatSearchParams,
} from 'helpers/Amplitude';
import { getLoginURL } from 'utils/url';
import { SanityRichText } from 'components/Common/SanityRichText';
import { getAlign } from 'components/SimpleText';
import {
  CloseButton,
  CtaVideo,
  ModalInnerContainer,
  VideoContainer,
} from 'components/SquareImageSlider/SquareImageSlider.styles';
import { YoutubeModal } from 'components/Common/YoutubeModal/YoutubeModal';
import {
  generateYoutubeEmbedLink,
  renderYoutubeModalButton,
} from 'components/Common/YoutubeModal/YoutubeModal.helpers';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { ResponsiveImage } from 'components/Common/GatsbyImage/ResponsiveImage';
import { useSliderContext } from 'Context/Slider/Slider.context';
import {
  SplitSliderInlineDotContainer,
  InlineDot,
} from 'components/Common/Slider/Slider.styles';
import { getUrl } from 'helpers/LinkObject';

function renderTitle(props: MediaWithTextProps, isMobile?: boolean) {
  const {
    preTitle,
    title,
    titleColorMobile,
    titleColorDesktop,
    layout,
    mobileTitleAlignment,
  } = props;
  const renderPreTitle = () => {
    return (
      <StyledPreTitle
        className="PreTitle"
        textColor={[
          titleColorMobile || null,
          titleColorMobile || null,
          titleColorDesktop || null,
        ]}
        mobile={isMobile}
        variant={layout}
        textAlign={[getAlign(mobileTitleAlignment), getAlign('left')]}
      >
        {preTitle}
      </StyledPreTitle>
    );
  };
  const renderMainTitle = () => {
    return (
      <StyledMediaWithTextTitle
        className="Title"
        textColor={[
          titleColorMobile || null,
          titleColorMobile || null,
          titleColorDesktop || null,
        ]}
        mobile={isMobile}
        variant={layout}
        textAlign={[getAlign(mobileTitleAlignment), getAlign('left')]}
        dangerouslySetInnerHTML={{ __html: title || '' }}
      />
    );
  };

  return (
    <>
      {preTitle && renderPreTitle()}
      {title && renderMainTitle()}
    </>
  );
}

function renderText(props: MediaWithTextProps, isMobile?: boolean) {
  const {
    text,
    mobileText,
    textColorMobile,
    textColorDesktop,
    layout,
    splitImages,
  } = props;

  const blockText = isMobile ? mobileText : text;
  const { isSplit } = getVariant(props);

  return (
    <>
      <StyledMediaWithTextText
        className="Text"
        textColor={[
          textColorMobile || null,
          textColorMobile || null,
          textColorDesktop || null,
        ]}
        mobile={isMobile}
        variant={layout}
      >
        <SanityRichText blocks={blockText} />
      </StyledMediaWithTextText>
      {isSplit && (
        <ResponsiveImage
          className="Image"
          mobileImage={getSimplifiedImageData(splitImages?.mobile)}
          desktopImage={getSimplifiedImageData(splitImages?.desktop)}
        />
      )}
    </>
  );
}

function renderMobileMedia(
  props: MediaWithTextProps,
  playButtonHandler?: (e: MouseEvent) => Promise<any>
) {
  const { images } = props;
  const {
    isMobileImageEmbed,
    isMobileImageTextEmbed,
    isMobileImageEmbedAllTop,
    isSmallLadder,
  } = getVariant(props);

  const mobileImage = getSimplifiedImageData(images.mobile);

  return (
    <StyledMediaWithTextMedia mobile>
      {mobileImage && (
        <StyledMediaWithTextImg
          className="Image-mobile"
          borderRadius={getBorderRadius(props)}
          image={mobileImage}
          imgStyle={{ objectFit: isSmallLadder ? 'contain' : 'cover' }}
          alt="Mobile Image"
        />
      )}
      {playButtonHandler && renderYoutubeModalButton(playButtonHandler)}
      <StyledMediaWithTextMediaWrap
        variant={isMobileImageEmbedAllTop ? 'embedAllTop' : ''}
      >
        {(isMobileImageEmbed ||
          isMobileImageTextEmbed ||
          isMobileImageEmbedAllTop) &&
          renderTitle(props, true)}
        {(isMobileImageTextEmbed || isMobileImageEmbedAllTop) &&
          renderText(props, true)}
      </StyledMediaWithTextMediaWrap>
    </StyledMediaWithTextMedia>
  );
}

function renderDesktopMedia(
  props: MediaWithTextProps,
  playButtonHandler?: (e: MouseEvent) => Promise<any>
) {
  const { images } = props;

  const desktopImage = getSimplifiedImageData(images.desktop);
  if (!desktopImage) return null;

  const { isSmallLadder } = getVariant(props);
  if (isSmallLadder) {
    return (
      <StyledMediaWithTextMediaLadderSmall desktop>
        <StyledMediaWithTextImgLadderSmall
          className="Image-desktop"
          borderRadius={getBorderRadius(props)}
          image={desktopImage}
          imgStyle={{ objectFit: 'contain' }}
          alt={props.title || ''}
        />
      </StyledMediaWithTextMediaLadderSmall>
    );
  }

  return (
    <StyledMediaWithTextMedia desktop>
      <StyledMediaWithTextImg
        className="Image-desktop"
        borderRadius={getBorderRadius(props)}
        image={desktopImage}
        alt="Desktop image"
      />
      {playButtonHandler && renderYoutubeModalButton(playButtonHandler)}
    </StyledMediaWithTextMedia>
  );
}

function renderContent(
  props: MediaWithTextProps,
  isMobile?: boolean,
  showTitle?: boolean
) {
  const { layout } = props;
  return (
    <StyledMediaWithTextInfo
      desktop={!isMobile}
      mobile={isMobile}
      variant={layout}
    >
      {showTitle && renderTitle(props, isMobile)}
      {renderText(props, isMobile)}
      {renderCTAButton(props)}
    </StyledMediaWithTextInfo>
  );
}

function renderCTAButton(props: MediaWithTextProps) {
  const { enableCta, ctaText, ctaLink, ctaLinkObject } = props;
  const { isSplit } = getVariant(props);

  if (!enableCta || !isSplit) return false;
  return (
    <Button
      href={
        ctaLinkObject?.page || ctaLinkObject?.url || ctaLink
          ? getUrl(ctaLinkObject, ctaLink)
          : getLoginURL()
      }
      variant="primary"
      onClick={awaitClick((e) =>
        logEvent('Sellpage: Click', { key: e.currentTarget.href })
      )}
    >
      {ctaText}
    </Button>
  );
}

function getVariant(props: MediaWithTextProps) {
  const { layout, mobileImageLocation } = props;
  const isSplit = layout === 'split' || layout === 'splitSlider';
  const isSmallLadder = layout === 'smallLadder';
  return {
    isSplit,
    isSmallLadder,
    isMobileImageTop: mobileImageLocation === 'top' || isSmallLadder,
    isMobileImageMiddle: mobileImageLocation === 'middle',
    isMobileImageBottom: mobileImageLocation === 'bottom',
    isMobileImageEmbed: mobileImageLocation === 'embed title',
    isMobileImageTextEmbed: mobileImageLocation === 'embed title & text',
    isMobileImageEmbedAllTop: mobileImageLocation === 'embed all top',
  };
}

function getBorderRadius(props: MediaWithTextProps) {
  return props.roundedCornersMedia ? '15px' : '0px';
}

export interface MediaWithTextProps {
  images: ScreenAware<SanityImage>;
  splitImages?: ScreenAware<SanityImage>;
  layout?: 'small' | 'split' | 'splitSlider' | 'large' | string;
  backgroundColor?: string;
  preTitle?: string;
  title?: string;
  mobileTitleAlignment: 'left' | 'center' | string;
  titleColorMobile?: string;
  titleColorDesktop?: string;
  text?: Maybe<SanityBlock>[];
  mobileText?: Maybe<SanityBlock>[];
  textColorMobile?: string;
  textColorDesktop?: string;
  textLocation?: string;
  mobileImageLocation?: string;
  roundedCornersMedia?: boolean;
  enableCta?: boolean;
  ctaText?: string;
  ctaLink?: string; // deprecated
  ctaLinkObject?: SanityLinkObject;
  youtubeUrl?: string;
}

const MediaWithText: React.FunctionComponent<MediaWithTextProps> = (
  props: MediaWithTextProps
): React.ReactElement => {
  const { layout, backgroundColor, textLocation, youtubeUrl } = props;

  const {
    isMobileImageTop,
    isMobileImageMiddle,
    isMobileImageEmbed,
    isMobileImageTextEmbed,
    isMobileImageEmbedAllTop,
    isMobileImageBottom,
  } = getVariant(props);

  const { search } = useLocation();

  const { slider, currentSlide, isDragging } = useSliderContext();

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handlePlayButtonClick = (e: MouseEvent) => {
    const params = mapAndFormatSearchParams(new URLSearchParams(search));
    setShowVideoModal(true);
    e.preventDefault();
    return logEvent('CTA Click', {
      ...((params.get('icid') && { icid: params.get('icid') }) || {}),
    });
  };

  return (
    <StyledMediaWithText background={backgroundColor} variant={layout}>
      {showVideoModal && (
        <YoutubeModal onOverlayClick={() => setShowVideoModal(false)}>
          <ModalInnerContainer>
            <CloseButton onClick={() => setShowVideoModal(false)} />
            <VideoContainer>
              <CtaVideo
                src={generateYoutubeEmbedLink(youtubeUrl || '')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              ></CtaVideo>
            </VideoContainer>
          </ModalInnerContainer>
        </YoutubeModal>
      )}
      <StyledMediaWithTextContainer variant={layout}>
        <StyledMediaWithTextWrap
          flexDirection={textLocation === 'Right' ? 'row' : 'row-reverse'}
        >
          {/* MOBILE */}
          {isMobileImageTop && (
            <>
              {youtubeUrl
                ? renderMobileMedia(props, handlePlayButtonClick)
                : renderMobileMedia(props)}
              {slider && layout === 'splitSlider' && (
                <SplitSliderInlineDotContainer isActive={!isDragging}>
                  {[...Array(slider?.details().size).keys()].map(
                    (slideIndex: number): React.ReactElement => {
                      const index = slideIndex || 0;
                      return (
                        <InlineDot
                          data-test-id="dot"
                          key={slideIndex}
                          onClick={(): void => {
                            slider.moveToSlide(index);
                          }}
                          isActive={slideIndex === currentSlide}
                        ></InlineDot>
                      );
                    }
                  )}
                </SplitSliderInlineDotContainer>
              )}
              {renderTitle(props, true)}
              {renderContent(props, true)}
            </>
          )}

          {isMobileImageMiddle && (
            <>
              {renderTitle(props, true)}
              {youtubeUrl
                ? renderMobileMedia(props, handlePlayButtonClick)
                : renderMobileMedia(props)}
              {renderContent(props, true)}
            </>
          )}

          {isMobileImageBottom && (
            <>
              {renderTitle(props, true)}
              {renderContent(props, true)}
              {youtubeUrl
                ? renderMobileMedia(props, handlePlayButtonClick)
                : renderMobileMedia(props)}
            </>
          )}

          {isMobileImageEmbed && (
            <>
              {youtubeUrl
                ? renderMobileMedia(props, handlePlayButtonClick)
                : renderMobileMedia(props)}
              {renderContent(props, true)}
            </>
          )}

          {(isMobileImageTextEmbed || isMobileImageEmbedAllTop) &&
            (youtubeUrl
              ? renderMobileMedia(props, handlePlayButtonClick)
              : renderMobileMedia(props))}

          {/* DESKTOP */}

          {youtubeUrl
            ? renderDesktopMedia(props, handlePlayButtonClick)
            : renderDesktopMedia(props)}
          {renderContent(props, false, true)}
        </StyledMediaWithTextWrap>
      </StyledMediaWithTextContainer>
    </StyledMediaWithText>
  );
};

export default MediaWithText;
