import React, { useContext } from 'react';
import { Image } from 'components/Image/Image';
import {
  BorderWrapper,
  Positioning,
  SecondaryBackground,
  Title,
  Content,
  Blockquote,
} from 'components/BorderContent/BorderContent.styles';
import {
  SanityBorderContentFragment,
  SanityFlowPageQuote,
  SanityImage,
  SanityTextObject,
} from 'graphql-types';
import { ThemeContext } from 'styled-components';

export const BorderContent: React.FC<{ data: SanityBorderContentFragment }> = ({
  data: { backgroundColor, title, rows },
}) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <SecondaryBackground
        backgroundColor={backgroundColor?.hex || theme.colors.basic200}
      />
      <Positioning>
        <BorderWrapper>
          {title && <Title>{title}</Title>}
          {(rows || []).map(
            (
              item: NonNullable<
                NonNullable<SanityBorderContentFragment['rows']>[0]
              >
            ) => {
              switch (item._type) {
                case 'textObject':
                  return <Content>{(item as SanityTextObject).text}</Content>;
                case 'image':
                  return <Image {...(item as SanityImage).asset} />;
                case 'flowPageQuote':
                  const { quote, author } = item as SanityFlowPageQuote;
                  return (
                    <Blockquote>
                      <p>{quote}</p>
                      <cite>{author}</cite>
                    </Blockquote>
                  );
                default:
                  return null;
              }
            }
          )}
        </BorderWrapper>
      </Positioning>
    </>
  );
};
