import styled from 'styled-components';
import { Container } from 'components/Common/Layout';

import {
  space,
  fontSize,
  flexDirection,
  background,
  BackgroundProps,
  color,
} from 'styled-system';

export const StickyContainer = styled(Container)<BackgroundProps>`
  ${background}
`;

export const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.basic100};
  display: flex;
  justify-content: space-between;
  ${space({ py: ['10px', '25px'] })}
  ${flexDirection({ flexDirection: ['column', 'row'] })}
`;

export const Copy = styled.p`
  color: ${({ theme }) => theme.colors.basic100};
  ${color}
  font-weight: ${({ theme }) => theme.fontWeights.black};
  white-space: nowrap;
  ${space({ pb: ['15px', '0'] })};
  ${fontSize({ fontSize: ['18px', '20px', '26px'] })};
`;
