import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import MediaWithText from 'components/MediaWithText';
import { SanitySellPageMediaWithText } from 'graphql-types';
import { ThemeContext } from 'styled-components';
interface Props {
  fields: SanitySellPageMediaWithText;
}

export const SanityMediaWithText = ({ fields }: Props) => {
  const theme = useContext(ThemeContext);
  return (
    <MediaWithText
      layout={fields.layout || 'small'}
      backgroundColor={fields.backgroundColor?.hex || theme.colors.basic100}
      preTitle={fields.preTitle}
      title={fields.title}
      mobileTitleAlignment={fields.mobileTitleAlignment || 'left'}
      titleColorMobile={fields.titleColorMobile?.hex}
      titleColorDesktop={fields.titleColorDesktop?.hex}
      text={fields._rawText}
      mobileText={fields._rawMobileText}
      textColorMobile={fields.textColorMobile?.hex || theme.colors.basic800}
      textColorDesktop={fields.textColorDesktop?.hex || theme.colors.basic800}
      textLocation={fields.textLocation || 'Right'}
      mobileImageLocation={fields.mobileImageLocation || 'top'}
      roundedCornersMedia={fields.roundedCornersMedia}
      enableCta={fields.enableCta}
      ctaText={fields.ctaText}
      ctaLink={fields.ctaLink} // deprecated
      ctaLinkObject={fields.ctaLinkObject}
      youtubeUrl={fields.youtubeUrl}
      images={{
        mobile: fields.mobileImage,
        desktop: fields.desktopImage,
      }}
      splitImages={{
        mobile: fields.splitMobileImage,
        desktop: fields.splitDesktopImage,
      }}
    />
  );
};
export const query = graphql`
  fragment SanitySellPageMediaWithText on SanitySellPageMediaWithText {
    _key
    _type
    __typename
    name
    layout
    backgroundColor {
      hex
    }
    preTitle
    title
    mobileTitleAlignment
    titleColorMobile {
      hex
    }
    titleColorDesktop {
      hex
    }
    _rawText
    _rawMobileText
    splitMobileImage {
      asset {
        gatsbyImageData(width: 800, placeholder: NONE)
      }
    }
    splitDesktopImage {
      asset {
        gatsbyImageData(width: 400, placeholder: NONE)
      }
    }
    textColorMobile {
      hex
    }
    textColorDesktop {
      hex
    }
    textLocation
    mobileImage {
      asset {
        gatsbyImageData(width: 800, placeholder: NONE)
      }
    }
    desktopImage {
      asset {
        gatsbyImageData(width: 490, placeholder: NONE)
      }
    }
    mobileImageLocation
    roundedCornersMedia
    enableCta
    ctaText
    ctaLink
    ctaLinkObject {
      ...LinkObject
    }
    youtubeUrl
  }
`;
