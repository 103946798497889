import React, { FC } from 'react';
import { SanitySellPageStickyHeader } from 'graphql-types';
import { Container, LogoContainer } from './StickyHeader.styles';
import { GatsbyImage } from 'components/Common/GatsbyImage';
import { getSimplifiedImageData } from 'helpers/SanityMapper';

const alignment = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export enum Horizontal {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export const StickyHeader: FC<SanitySellPageStickyHeader> = ({
  bgColor,
  desktopHeight,
  mobileHeight,
  title,
  url,
  logoImage,
  logoAlignment = 'left',
}) => {
  return (
    <Container
      height={[`${mobileHeight}px`, `${desktopHeight}px`]}
      background={bgColor?.hex}
    >
      <LogoContainer justifyContent={[alignment[logoAlignment as Horizontal]]}>
        <a href={url}>
          <GatsbyImage
            image={getSimplifiedImageData(logoImage)}
            alt={title || ''}
          />
        </a>
      </LogoContainer>
    </Container>
  );
};
