import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  fontSize,
  height,
  space,
  textAlign,
  width,
} from 'styled-utils';

export const SecondaryBackground = styled.div<ColorProps>`
  ${height({ height: ['128px', '115px'] })};
  ${color};
`;

export const Positioning = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${space({ mt: ['-128px', '-115px'] })};
`;

export const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  border: 5px solid ${({ theme }) => theme.colors.basic400};
  background-color: ${({ theme }) => theme.colors.basic100};
  ${space({ p: ['22px 15px', '55px'] })};
  ${width({ width: ['92%', '85%'] })};

  .gatsby-image-wrapper {
    width: 100%;
    ${space({ mb: [20, 50] })};
  }
`;

export const ContentWidth = css`
  ${width({ width: ['100%', '80%'] })};
`;

export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fonts.alternate};
  ${ContentWidth};
  color: ${({ theme }) => theme.colors.black};
  font-weight: 400;
  font-size: 28px;
  letter-spacing: normal;
  line-height: 1.3;
  text-transform: uppercase;
  ${textAlign({ textAlign: ['center', 'left'] })};
  ${space({ mb: [10, 40] })};
`;

export const Content = styled.p`
  ${ContentWidth};
  font-size: 15px;
  line-height: 1.61;
  ${space({ mb: [20, 40] })};
`;

export const Blockquote = styled.blockquote`
  ${ContentWidth};
  font-weight: 600;
  line-height: 1.2em;
  ${space({ mt: [0, 20] })};
  ${fontSize({ fontSize: [17, 19] })};

  cite {
    font-size: 15px;
    margin-top: 8px;
  }
`;
